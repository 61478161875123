<template>
  <v-card flat class="statistics">
    <v-row no-gutters v-if="moduleInfoLoading">
      <v-col v-for="n in 5" :key="n" cols="12">
        <v-skeleton-loader class="mx-auto" type="list-item-avatar"></v-skeleton-loader>
      </v-col>
    </v-row>
    <v-row v-else-if="!moduleInfoLoading" class="pa-0 mt-2"  :class="$vuetify.theme.dark ? 'dark-theme-chart' : ''">
      <v-col cols="12" lg="12">
        <!-- <h5 class="pb-1">{{ $t('moduleInfo') }}</h5> -->
        <highcharts :options="chartOptions"></highcharts>
      </v-col>
    </v-row>
  </v-card>
</template>
<script>
export default {
  props: ['moduleInfoLoading', 'chartOptions']
}
</script>
